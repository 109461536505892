// Common Rules
* {
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
}
body {
  font: $mainFontSize $novaFont;
  height: 100%;
  min-height: 100%;
  background-color: $deepBlue;
  color: $middleWhite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: $linkWhite;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}