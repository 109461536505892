/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width: 325px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 605px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 960px) {

  body {
    min-height:680px;
  }

  .top-notification {
    background-color: $darkDeepBlue;
    position: relative;

    .close {
      position: absolute;
      top:0;
      bottom:0;
      right:10px;
      margin:auto;
      width:inherit;
      height:2px;
      cursor: pointer;
      opacity:0.6;
      padding:10px;
      background-image: url($imgPath + 'cross-white.svg');
      background-repeat:no-repeat;
      -webkit-background-size:contain;
      background-size:contain;

      &:hover {
        opacity:1;
      }
    }

    p {
      font-size:10px;
      line-height:12px;
      padding:10px 50px 10px 20px;
    }

  }

  header,
  main,
  footer {
    padding-left: $sidePadding;
    padding-right: $sidePadding;

    .logo,
    .lng {
      padding-top:40px;
    }

  }

  header {
    .logo {
      margin-top:0;

      img {
        width:188px;
      }
    }
    .login-form {
      display: none;
    }
  }

  .video-slides {
    display: none;
  }

  main {
    padding-bottom:$footerHeight;
    position: initial;

    .login-form {
      display: block;
      position: absolute;
      top:50%;
      margin-top: -185px;
      right:$sidePadding;
      text-align: right;
      z-index:5;
      opacity:0;

      &:before {
        content: '';
        background-image: url($imgPath + 'lock-icon.svg');
        -webkit-background-size: contain;
        background-size:contain;
        background-repeat:no-repeat;
        height:15px;
        width:11px;
        display: inline-block;
        margin-right:5px;
        padding-top:5px;
        vertical-align: middle;
        opacity:0.2;

      }

      span {
        text-transform: uppercase;
        font-size:10px;
        letter-spacing: 1px;
        cursor: pointer;
        font-weight:700;
        @include transition(all, 0.2s, ease-in-out);

        &:hover {
          color: #fff;
        }
      }
    }

    section {
      position: absolute;
      top:50%;
      margin-top:-260px;
      z-index:3;

      img {
        max-width:440px;
        margin-top:$homeH1Top;
      }

      p {
        width:480px;
        margin-top:40px;
        margin-bottom:0;
        line-height:22px;
        font-size: $mainFontSize;
      }

    }

  }

  footer {
    text-align: right;
    margin: -$footerHeight auto 0;
    min-height: $footerHeight;

    .logo,
    .contact-info,
    .service-providers {
      text-align: left;
      display: inline-block;
      vertical-align: top;
      height:100px;
      padding-top:55px;
      font-size:$mainFontSize;
      margin-top: 0;
    }

    .logo {
      float: left;

      a {
        height:44px;
        width:44px;
      }
    }

    .contact-info {
      padding-top:50px;

      li {
        span {
          //width:25px;
          //font-size:10px;
        }

        a {
          font-size: $mainFontSize;
        }
      }
    }

    .service-providers {
      padding-left:110px;
      font-size:$mainFontSize;

      span {

      }

      li {
        &:not(:first-child) {
          padding-left:25px;
        }
        &:last-child {
          padding-left:30px;
        }

        a {
          height:14px;
        }

        &.pwc {
          a {
            height:33px;
          }
        }
      }

    }

    .copyright {
      margin-top: 0;
      text-align: left;
      font-size:12px;

      a {
        font-size:12px;
      }
    }

  }

  .modal {

    .content {
      padding:50px 55px 70px 65px;
      height:420px;
      width:488px;

      p {
        margin:55px auto 0;
        width:100%;
        height:auto;
        overflow: auto;
        line-height:23px;
      }
    }

  }

}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1395px) {


}

// IE10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

}

// Edge
@supports (-ms-accelerator:true) {

}