$imgPath : '/img/';

// Sizes
$headerHeight : 130px;
$containerMinWidth : 1120px;
$footerHeight : 225px;
$footerHeightMobile : 445px;
$smallSidePadding : 15px;
$sidePadding : 80px;
$mainFontSize : 14px;
$mobileFontSize : 28px;
$homeH1Top : 60px;

// Colors
$darkDeepBlue : rgba(15, 24, 40, 0.7);
$deepBlue : rgb(29, 39, 58);
$deepBlueOpacity : rgba(29, 39, 58, 0.9);
$violet : rgb(65,83,118);
$linkWhite : rgba(255,255,255, 0.8);
$middleWhite : rgba(255,255,255, 0.5);
$lowWhite : rgba(255,255,255, 0.3);

// Fonts
$novaFont : "proxima-nova",sans-serif;