.video-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  z-index: 0;;

  &:after {
    position: absolute;
    left:0;
    top:0;
    display: block;
    width:100%;
    height:100%;
    background-color: $deepBlueOpacity;
    content: '';
  }

  video {
    min-height: 100%;
    min-width: 100%;
    float: left;
  }
}

// Video images on mobile
.video-slides {
  position: relative;
  display: none;

  img {
    width:100%;
    visibility: hidden;
    opacity:0;
    position: absolute;
    top:0;
    @include transition();

    &.active {
      visibility: visible;
      opacity:0.4;
    }

    &.for-size {
      position: relative;
      visibility: hidden;
      opacity:0;
      display: block;
    }
  }

}

main {
  .login-form {
    display: none;
  }
}

form {
  display: none;
  text-align: right;
  padding-top:15px;
  position: relative;

  .m-b-1{
    display: block;

  }

  .error-tooltip {
    display: none;
    width:140px;
    height:50px;
    background-color: #d4d5d9;
    position: absolute;
    top:35px;
    right:100%;
    padding:15px 30px;
    margin-right:60px;
    z-index:5;
    font-weight:700;
    line-height:18px;
    text-align: left;
    color: #415376;
    -webkit-box-shadow: 10px 10px 32px 1px rgba(0,0,0,0.34);
    -moz-box-shadow: 10px 10px 32px 1px rgba(0,0,0,0.34);
    box-shadow: 10px 10px 32px 1px rgba(0,0,0,0.34);

    &:after,
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 0 40px 15px;
      border-color: transparent transparent transparent #d4d5d9;
      z-index:5;
      position: absolute;
      top:0;
      left:100%;
      content: '';
    }
    &:before {
      display: none;
    }
  }

  p {
    margin:20px 0;
  }
  input {
    width:200px;
    height:30px;
    border:none;
    padding:0 15px;
    @include border-radius(1px);

    &[type=submit] {
      width:100px;
      color: #fff;
      background-color: $violet;
      text-transform: uppercase;
      font-size:10px;
      font-weight:700;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
}