@mixin box-shadow($where,$color) {
  -webkit-box-shadow: $where $color;
  -moz-box-shadow: $where $color;
  box-shadow: $where $color;
}

@mixin gradient($startColor, $endColor) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image:    -moz-linear-gradient(top, $startColor, $endColor);
  background-image:     -ms-linear-gradient(top, $startColor, $endColor);
  background-image:      -o-linear-gradient(top, $startColor, $endColor);
  background-image:         linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffffff,EndColorStr=#e6e6e6) ;

}

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin background-rgba($r, $g, $b, $opacity) {
  background-color: transparent;
  $rgba: rgba($r, $g, $b, $opacity);
  $hex: ie-hex-str($rgba);
  background-color: $rgba;
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='" + $hex + ",endColorstr='" + $hex + "')";
  zoom:   1;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}


@mixin box-sizing($type: border-box) {
  -webkit-box-sizing:   $type;
  -moz-box-sizing:      $type;
  box-sizing:           $type;
}

@mixin columns($count, $gap) {
  -webkit-column-count: $count;
  -moz-column-count:    $count;
  column-count:         $count;

  -webkit-column-gap:   $gap;
  -moz-column-gap:      $gap;
  column-gap:           $gap;
}

@mixin flex($value: 1) {
  -webkit-box-flex: $value;
  -moz-box-flex:    $value;
  box-flex:         $value;
}

@mixin opacity($opacity: 0.5) {
  -moz-opacity:   $opacity;
  -khtml-opacity: $opacity;
  opacity:        $opacity;
  -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + ($opacity * 100) + ")";
  filter:         alpha(opacity=($opacity * 100));
}

@mixin resize($direction: both) {
  -webkit-resize: $direction;
  -moz-resize:    $direction;
  resize:         $direction;
}

@mixin text-shadow($x, $y, $blur, $color) {
  text-shadow: $x $y $blur $color;
}

@mixin rotate($deg: 0) {
  -moz-transform: rotate($deg + deg);
  -o-transform: rotate($deg + deg);
  -webkit-transform: rotate($deg + deg);
  -ms-transform: rotate($deg + deg);
  transform: rotate($deg + deg);

}

@mixin transform($params) {
  -webkit-transform: $params;
  -moz-transform:    $params;
  -o-transform:      $params;
  transform:         $params;
}

@mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
  -moz-transition:    $what $length $easing;
  -o-transition:      $what $length $easing;
  -webkit-transition: $what $length $easing;
  -ms-transition:     $what $length $easing;
  transition:         $what $length $easing;
}

@mixin delay($length) {
  -webkit-transition-delay: $length;
  -moz-transition-delay: $length;
  -ms-transition-delay: $length;
  -o-transition-delay: $length;
  transition-delay: $length;
}

@mixin duration($length) {
  -webkit-animation-duration: $length; /* Chrome, Safari, Opera */
  animation-duration: $length;
}

@mixin clear() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin foot() {
  content:"";
  position: static;
  display: table;
  height: 0%;
}

$basecharColor : #3a3a3a;
$baseHover     : #4466aa;

@mixin defaultBoxShadow() {
  -webkit-box-shadow: 1px 1px 0 0 rgba(192, 192, 192, 1);
  -moz-box-shadow:    1px 1px 0 0 rgba(192, 192, 192, 1);
  box-shadow:         1px 1px 0 0 rgba(192, 192, 192, 1);
}

@mixin noSelect() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin shadow() {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 1);
}