// Core
@import "core/_normilize";
@import "core/_variables";
@import "core/_mixins";
@import "core/_base";

// Pages
@import "modules/home";

.top-notification {
  background-color: $darkDeepBlue;
  position: relative;

  .close {
    height:2px;
    position: absolute;
    bottom:20px;
    right:20px;
    margin:auto;
    cursor: pointer;
    opacity:0.6;
    padding:10px;
    background-image: url($imgPath + 'cross-white.svg');
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    background-size:contain;


    &:hover {
      opacity:1;
    }
  }

  p {
    font-size:10px;
    line-height:12px;
    padding:10px 50px 10px 20px;
  }

}

header,
main,
footer {
  position: relative;
  padding-left: $smallSidePadding;
  padding-right: $smallSidePadding;
  z-index:1;
}
.wrapper {
  min-height: 100%;
  height: auto;
  position: relative;
}

header {
  min-height: $headerHeight;
  font-size:0;
  position: relative;

  .logo,
  .lng {
    font-size:$mainFontSize;
    width:50%;
    display: inline-block;
    vertical-align: top;
    padding-top:30px;
  }

  .logo {
    padding:45px 0;
    max-width:188px;
    width:100%;

    img {
      width:100%;
    }

  }

  .lng {
    text-align: right;
    font-size:10px;
    text-transform: uppercase;
    letter-spacing: 1px;

    ul {

      li {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-size:11px;
        font-weight:700;
        color: $lowWhite;
        cursor: default;

        &:not(:last-child) {
          margin-right:15px;
        }
      }
    }
  }

  .login-form {
    font-size:$mainFontSize;
    margin:0 -15px;
    position: relative;

    span {
      position: absolute;
      bottom:100%;
      right:0;
      display: block;
      width:15px;
      height:22px;
      padding:15px 20px;

      img {
        width:100%;
        opacity:0.5;
      }
    }

    form,
    .cross {
      display: none;
    }

    &.toggle {

      span {
        background-color: $darkDeepBlue;
        width:20px;
        height:20px;
        padding:17.5px;

        .lock {
          display: none;
        }

        .cross {
          display: block;
        }

      }

      form {
        text-align: left;
        display: block;
        background-color: $darkDeepBlue;
        padding:1px $smallSidePadding;

        .error-tooltip {
          top: 30px;
          background-color: rgba(255,255,255,.9);
          height: 30px;
          font-size: 12px;
          line-height: 16px;
          bottom: 0;
          left: inherit;
          right: inherit;
          margin-right: 0;
          margin-left:90px;
          font-weight:500;

          &:after {
            display: none;
          }
          &:before {
            display: block;
            left:inherit;
            right:100%;
            border-width: 30px 15px 30px 0;
            border-color: transparent rgba(255, 255, 255, 0.9) transparent transparent;
          }
        }
      }
    }
  }

}

main {
  padding-bottom:$footerHeightMobile;

  section {
    &.animated {
      @include duration(5s);
    }

    img {
      margin:35px 0 0;
      width:100%;
      max-width:404px;
    }

    p {
      margin:25px 0;
      line-height:22px;
    }

  }

}

footer {
  margin: -$footerHeightMobile auto 0;
  min-height: $footerHeightMobile;
  position: relative;
  background-color: $darkDeepBlue;
  font-weight:700;
  overflow: hidden;
  opacity:0;
  &.animated {
    @include duration(2s);
  }

  .logo,
  .contact-info,
  .service-providers,
  .copyright {
    opacity:0;

    &.animated {
      @include duration(2s);
    }
  }

  .logo {
    margin-top:30px;

    a {
      display: block;
      width:44px;
      height:44px;

      img {
        width:100%;
      }
    }
  }

  .contact-info {
    margin-top:30px;

    li {
      list-style-type: none;
      padding:3px 0;

      span {
        color: rgba(255, 255, 255, 0.2);
        font-size:12px;
        font-weight:500;
        letter-spacing: 1px;
        display: block;
      }

      a {
        color: #fff;
        opacity:0.5;
        display: inline-block;
        font-weight:500;
        @include transition(all, 0.2s, ease-in-out);

        &:not(.email) {
          cursor: default;
          &:hover {
            text-decoration: none;
          }
        }

        &.email {
          //margin:8px 0;

          &:hover {
            opacity:1;
            text-decoration: none;
          }
        }
      }
    }
  }

  .service-providers {
    margin-top:55px;

    span {
      display: block;
      padding-bottom:15px;
      font-weight:500;
    }

    li {
      display: inline-block;

      &:not(:first-child) {
        padding-left:10px;
      }

      a {
        display: block;
        height:14px;

        img {
          height:100%;
          opacity:0.3;
          @include transition(all, 0.2s, ease-in-out);

          &:hover {
            opacity:1;
          }
        }
      }

      &.pwc {
        position: relative;
        top: 2px;
        a {
          height:33px;
        }
      }
    }

  }

  .copyright {
    margin-top:70px;
    height:auto;
    padding-bottom:30px;
    color: rgba(255, 255, 255, 0.2);
    font-size:12px;
    font-weight:500;

    a {
      color: #fff;
      opacity:0.5;
      @include transition(all, 0.2s, ease-in-out);

      &:hover {
        text-decoration: none;
        opacity:1;
      }
    }
  }

}

.modal {
  position: fixed;
  top:0;
  left:0;
  z-index:6;
  width:100%;
  height:100%;
  display: none;

  .overlay {
    width:100%;
    height:100%;
    background-color: $darkDeepBlue;
  }

  .content {
    position: absolute;
    display: block;
    background-color: #fff;
    color: #232323;
    height:200px;
    max-width:488px;
    margin:auto;
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding:50px 20px 70px 20px;

    &.animated {
      @include duration(0.4s);
    }

    .close {
      background-image: url($imgPath + 'cross.svg');
      -webkit-background-size: contain;
      background-size:contain;
      background-repeat:no-repeat;
      width:20px;
      height:20px;
      position: absolute;
      right:10px;
      top: 10px;
      cursor: pointer;

    }

    h1 {
      text-transform: uppercase;
      font-size:18px;
      letter-spacing: 2px;
      padding-bottom:15px;
      margin:0;
      border-bottom:1px solid #ccc;
    }

    p {
      margin-top:20px;
      height:190px;
      overflow: auto;
    }
  }

}

// Its here because of mobile first
@import "modules/responsive";